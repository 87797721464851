import React from "react";

const PlanCard = ({
  title,
  hours,
  minutes,
  price,
  originalPrice,
  discountPercentage,
}) => (
  <div className="border border-blue-500 rounded-lg text-center p-5 bg-white font-medium shadow-lg hover:shadow-sky-700 transition-shadow duration-300 flex flex-col justify-between w-full max-w-xs">
    <div>
      <div className="py-2 h-16 flex items-center justify-center">
        <span className="text-lg font-semibold">{title}</span>
      </div>
      <div className="font-bold text-xl py-2">{hours}</div>
      <div className="font-bold text-sm py-2">{minutes}</div>
    </div>
    <div className="mt-4">
      {originalPrice && (
        <div className="text-sm line-through text-gray-500">
          ${originalPrice} SGD
        </div>
      )}
      <div className="text-lg font-bold text-green-600">${price} SGD</div>
      {discountPercentage && (
        <div className="text-sm text-red-500">Save {discountPercentage}%</div>
      )}
    </div>
  </div>
);

export default PlanCard;
