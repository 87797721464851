import React, { useState, useEffect } from "react";
import { auth, firestore } from "../../firebase/firebase";
import {
  doc,
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
} from "firebase/firestore";

const HistoryPage = () => {
  const [timeLeft, setTimeLeft] = useState(null);
  const [purchases, setPurchases] = useState([]);
  const [transcriptions, setTranscriptions] = useState([]);
  const [view, setView] = useState("purchases");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const fetchUserData = async () => {
    const user = auth.currentUser;
    if (user) {
      const userId = user.uid;

      const userDocRef = doc(firestore, "users", userId);
      onSnapshot(userDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          setTimeLeft(docSnapshot.data().timeLeft);
        }
      });

      const customersCollectionRef = collection(firestore, "customers");
      const customerDocRef = doc(customersCollectionRef, userId);
      const transactionsCollectionRef = collection(
        customerDocRef,
        "transactions"
      );
      const transactionsSnapshot = await getDocs(transactionsCollectionRef);
      const fetchedPurchases = transactionsSnapshot.docs.map((doc) => {
        const data = doc.data();
        data.id = doc.id;
        return {
          ...data,
          plan: data.plan || "N/A",
          description: data.description || "N/A",
          amount: data.amount || "N/A",
        };
      });

      fetchedPurchases.sort((a, b) => {
        if (a.created && b.created) {
          return b.created.toDate() - a.created.toDate();
        }
        return 0;
      });

      setPurchases(fetchedPurchases);

      const transcriptionsCollectionRef = collection(
        firestore,
        "transcriptions"
      );
      const transcriptionsQuery = query(
        transcriptionsCollectionRef,
        where("uid", "==", userId)
      );
      const transcriptionsSnapshot = await getDocs(transcriptionsQuery);
      const fetchedTranscriptions = transcriptionsSnapshot.docs.map((doc) => {
        const data = doc.data();
        data.id = doc.id;
        return data;
      });

      fetchedTranscriptions.sort((a, b) => {
        if (a.createdAt && b.createdAt) {
          return b.createdAt.toDate() - a.createdAt.toDate();
        }
        return 0;
      });

      setTranscriptions(fetchedTranscriptions);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const formatTime = (seconds) => {
    const totalSeconds = Math.floor(seconds);
    const minutes = Math.floor(totalSeconds / 60);
    const remainingSeconds = totalSeconds % 60;
    const formattedSeconds =
      remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
    return `${minutes}:${formattedSeconds}`;
  };

  const formatDateTime = (timestamp) => {
    if (!timestamp) return { formattedDate: "N/A", formattedTime: "N/A" };
    const date = new Date(timestamp.seconds * 1000);
    const formattedDate = date.toLocaleDateString("en-GB");
    const formattedTime = date.toLocaleTimeString("en-GB", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    return { formattedDate, formattedTime };
  };

  const getCurrentPageData = (data) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 to-purple-200 p-8">
      <div className="container mx-auto p-8 bg-white rounded-lg shadow-lg">
        <div className="text-center mb-8">
          <h1 className="text-4xl font-bold text-gray-800 mb-2">
            User Dashboard
          </h1>
          {timeLeft !== null ? (
            <h2 className="text-xl text-gray-600">
              Remaining Time:{" "}
              <span className="text-blue-500 font-semibold">
                {formatTime(timeLeft)} minutes
              </span>
            </h2>
          ) : (
            <h2 className="text-xl text-gray-600">Loading remaining time...</h2>
          )}
        </div>
        <div className="mb-8 flex justify-center">
          <select
            className="p-2 border rounded bg-gray-50 focus:ring focus:border-blue-300"
            value={view}
            onChange={(e) => {
              setView(e.target.value);
              setCurrentPage(1);
            }}
          >
            <option value="purchases">Purchases</option>
            <option value="transcriptions">Transcriptions</option>
          </select>
        </div>
        {view === "purchases" ? (
          <div>
            <h2 className="text-2xl font-bold mb-4">Purchases</h2>
            <div className="mb-8">
              {purchases.length > 0 ? (
                <div>
                  <div className="grid grid-cols-6 gap-4 font-bold mb-4">
                    <div>Date</div>
                    <div>Time of Purchase</div>
                    <div>Plan</div>
                    <div>Description</div>
                    <div>Amount</div>
                  </div>
                  {getCurrentPageData(purchases).map((purchase, index) => {
                    const { created, plan, description, amount } = purchase;

                    const { formattedDate, formattedTime } =
                      formatDateTime(created);

                    return (
                      <div
                        key={index}
                        className="grid grid-cols-6 gap-4 mb-2 p-4 border rounded-lg shadow bg-blue-50"
                      >
                        <div>{formattedDate}</div>
                        <div>{formattedTime}</div>
                        <div>{plan || "N/A"}</div>
                        <div>{description || "N/A"}</div>
                        <div>{amount || "N/A"}</div>
                      </div>
                    );
                  })}
                  <div className="flex justify-between mt-4">
                    <button
                      onClick={handlePreviousPage}
                      disabled={currentPage === 1}
                      className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:opacity-50"
                    >
                      Previous
                    </button>
                    <button
                      onClick={handleNextPage}
                      disabled={currentPage * itemsPerPage >= purchases.length}
                      className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:opacity-50"
                    >
                      Next
                    </button>
                  </div>
                </div>
              ) : (
                <div>No purchases found.</div>
              )}
            </div>
          </div>
        ) : (
          <div>
            <h2 className="text-2xl font-bold mb-4">Transcriptions</h2>
            <div className="mb-8">
              {transcriptions.length > 0 ? (
                <div>
                  <div className="grid grid-cols-4 gap-4 font-bold mb-4">
                    <div>Date</div>
                    <div>Time of Recording</div>
                    <div>Duration</div>
                  </div>
                  {getCurrentPageData(transcriptions).map(
                    (transcription, index) => {
                      const { formattedDate, formattedTime } = formatDateTime(
                        transcription.createdAt
                      );
                      const duration = transcription.duration_mins.toFixed(0);

                      return (
                        <div
                          key={index}
                          className="grid grid-cols-4 gap-4 mb-2 p-4 border rounded-lg shadow bg-green-50"
                        >
                          <div>{formattedDate}</div>
                          <div>{formattedTime}</div>
                          <div>{duration} minutes</div>
                        </div>
                      );
                    }
                  )}
                  <div className="flex justify-between mt-4">
                    <button
                      onClick={handlePreviousPage}
                      disabled={currentPage === 1}
                      className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 disabled:opacity-50"
                    >
                      Previous
                    </button>
                    <button
                      onClick={handleNextPage}
                      disabled={
                        currentPage * itemsPerPage >= transcriptions.length
                      }
                      className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 disabled:opacity-50"
                    >
                      Next
                    </button>
                  </div>
                </div>
              ) : (
                <div>No transcriptions found.</div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HistoryPage;
