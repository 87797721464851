import React, { useState } from "react";
import { Link } from "react-router-dom";
import { doPasswordReset } from "../../../firebase/auth";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await doPasswordReset(email);
      setMessage(
        "Password reset email sent. Please check your inbox or junk email."
      );
      setError(null);
    } catch (error) {
      setError("Failed to send password reset email. Please try again.");
      setMessage("");
    }
  };

  return (
    <main className="flex items-center justify-center w-full h-screen bg-gray-100 p-4">
      <div className="bg-white shadow-xl rounded-lg flex flex-col md:flex-row w-full max-w-4xl">
        <div className="md:w-1/2 p-8">
          <h1 className="text-3xl font-bold mb-4 text-center">
            Forgot Password
          </h1>
          <form onSubmit={handleResetPassword} className="space-y-5">
            <div>
              <label className="text-sm text-gray-600 font-bold">Email</label>
              <input
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg transition duration-300"
              />
            </div>

            {message && <p className="text-green-600">{message}</p>}
            {error && <p className="text-red-600">{error}</p>}

            <button
              type="submit"
              className="w-full px-4 py-2 text-white font-medium bg-indigo-600 rounded-lg hover:bg-indigo-700 hover:shadow-xl transition duration-300"
            >
              Reset Password
            </button>
          </form>
          <div className="text-center mt-4">
            <Link to="/login" className="hover:underline">
              Back to Login
            </Link>
          </div>
        </div>
        <div className="md:w-1/2 p-8 bg-gray-50 flex items-center justify-center">
          <img
            src="/logo512.png"
            alt="Illustration"
            className="w-full h-auto"
          />
        </div>
      </div>
    </main>
  );
};

export default ForgetPassword;
