import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { auth, firestore } from "../../firebase/firebase";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import { FaMicrophone, FaUpload, FaArrowLeft } from "react-icons/fa";
import Modal from "./Modal";
import "./styles.css";

const PurchasePromptModal = ({ onClose }) => (
  <div
    className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
    style={{ zIndex: 1000 }}
  >
    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
      <div className="mt-3 text-center">
        <p className="text-gray-800 text-lg font-medium mb-4">
          The remaining time is too short for a recording. Please purchase more
          time.
        </p>
        <div className="flex justify-around items-center">
          <button
            className="text-gray-500 hover:text-gray-800 transition duration-150 ease-in-out"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
);

const TimeWarningModal = ({ onClose, message }) => (
  <div
    className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
    style={{ zIndex: 1000 }}
  >
    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
      <div className="mt-3 text-center">
        <p className="text-gray-800 text-lg font-medium mb-4">{message}</p>
        <div className="flex justify-around items-center">
          <button
            className="text-gray-500 hover:text-gray-800 transition duration-150 ease-in-out"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
);

const UploadFailureModal = ({ onClose }) => (
  <div
    className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
    style={{ zIndex: 1000 }}
  >
    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
      <div className="mt-3 text-center">
        <p className="text-gray-800 text-lg font-medium mb-4">
          Uploading failed. The time consumed will be returned.
        </p>
        <div className="flex justify-around items-center">
          <button
            className="text-gray-500 hover:text-gray-800 transition duration-150 ease-in-out"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
);

const MainPage = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [recordedFile, setRecordedFile] = useState(null);
  const [mediaBlobUrl, setMediaBlobUrl] = useState(null);
  const [audioDuration, setAudioDuration] = useState(null);
  const [showRecordingButtons, setShowRecordingButtons] = useState(false);
  const [showFileInput, setShowFileInput] = useState(false);
  const [showRecordingDownload, setShowRecordingDownload] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const [initialTimeLeft, setInitialTimeLeft] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showSilenceNotification, setShowSilenceNotification] = useState(false);
  const [showPurchasePrompt, setShowPurchasePrompt] = useState(false);
  const [showTimeWarning, setShowTimeWarning] = useState(false);
  const [hasShownOneMinuteWarning, setHasShownOneMinuteWarning] =
    useState(false);
  const [hasShownThirtySecondWarning, setHasShownThirtySecondWarning] =
    useState(false);
  const [showUploadFailure, setShowUploadFailure] = useState(false);
  const mediaRecorderRef = useRef(null);

  useEffect(() => {
    const fetchUserTime = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(firestore, "users", user.uid);

        const unsubscribe = onSnapshot(
          userDocRef,
          (docSnapshot) => {
            if (docSnapshot.exists()) {
              const userData = docSnapshot.data();
              const fetchedTimeLeft =
                userData.timeLeft !== undefined ? userData.timeLeft : 15 * 60;
              setTimeLeft(fetchedTimeLeft);
              setInitialTimeLeft(fetchedTimeLeft);
              console.log(
                "Real-time update - Current timeLeft:",
                fetchedTimeLeft
              );
            } else {
              console.log("No user data found, setting default time.");
              setTimeLeft(15 * 60);
              setInitialTimeLeft(15 * 60);
            }
          },
          (error) => {
            console.error("Error fetching user data:", error);
          }
        );

        return () => unsubscribe();
      }
    };

    fetchUserTime();
  }, []);

  useEffect(() => {
    if (timeLeft !== null) {
      console.log("Current timeLeft:", timeLeft);
      if (timeLeft < 60 && isRecording && !hasShownOneMinuteWarning) {
        setShowTimeWarning(true);
        setHasShownOneMinuteWarning(true);
      } else if (timeLeft < 30 && isRecording && !hasShownThirtySecondWarning) {
        setShowTimeWarning(true);
        setHasShownThirtySecondWarning(true);
      }
    }
  }, [
    timeLeft,
    isRecording,
    hasShownOneMinuteWarning,
    hasShownThirtySecondWarning,
  ]);

  const formatTime = (seconds) => {
    const totalSeconds = Math.round(seconds);
    const minutes = Math.floor(totalSeconds / 60);
    const remainingSeconds = totalSeconds % 60;
    const formattedSeconds =
      remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
    return `${minutes}:${formattedSeconds}`;
  };

  const updateTimeLeftInFirestore = async (newTimeLeft) => {
    const user = auth.currentUser;
    if (user) {
      const userDocRef = doc(firestore, "users", user.uid);
      await updateDoc(userDocRef, { timeLeft: newTimeLeft });
    }
  };

  const stopRecording = useCallback(async () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
    if (startTime !== null) {
      const endTime = Date.now();
      const durationInMilliseconds = endTime - startTime;
      const durationInSeconds = durationInMilliseconds / 1000;
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(firestore, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const fetchedTimeLeft = userData.timeLeft;
          const newTimeLeft = fetchedTimeLeft - durationInSeconds;
          setTimeLeft(newTimeLeft);
          await updateTimeLeftInFirestore(newTimeLeft);
        } else {
          setTimeLeft(15 * 60 - durationInSeconds);
          await updateTimeLeftInFirestore(15 * 60 - durationInSeconds);
        }
      }
      setStartTime(null);
    }
    setIsRecording(false);
    setShowRecordingDownload(true);
    setShowSilenceNotification(false);
    setShowTimeWarning(false);
    setHasShownOneMinuteWarning(false);
    setHasShownThirtySecondWarning(false);
  }, [startTime]);

  useEffect(() => {
    let timer;
    if (isRecording && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => Math.max(prevTime - 1, 0));
      }, 1000);
    } else if (timeLeft === 0) {
      setIsRecording(false);
    }

    return () => clearInterval(timer);
  }, [isRecording, timeLeft]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const audio = new Audio(URL.createObjectURL(file));
      audio.onloadedmetadata = () => {
        setAudioDuration(audio.duration);
      };
    } else {
      console.error("Invalid file type.");
    }
  };

  const handleFileUpload = async (fileToUpload) => {
    if (timeLeft < 60) {
      setShowPurchasePrompt(true);
      return;
    }

    if (!fileToUpload) {
      setUploadStatus("No file selected.");
      return;
    }

    setUploadStatus("Uploading...");

    const reader = new FileReader();
    reader.onloadend = async () => {
      const arrayBuffer = reader.result;
      const formData = new FormData();
      formData.append(
        "file",
        new Blob([arrayBuffer], { type: fileToUpload.type }),
        fileToUpload.name || "recording.m4a"
      );

      try {
        const response = await axios.post(
          "https://sendaudiofiletranscribe-mtlb3ha4qq-uc.a.run.app",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              uid: auth.currentUser.uid,
              "api-key": "summynotetaker29699999998655669478",
            },
          }
        );

        setUploadStatus(
          "File uploaded successfully! The transcript will be sent to you shortly. The page will reload soon."
        );
        console.log(response.data);

        const user = auth.currentUser;
        if (user) {
          const transcriptionsCollectionRef = collection(
            firestore,
            "transcriptions"
          );
          const transcriptionsQuery = query(
            transcriptionsCollectionRef,
            where("uid", "==", user.uid)
          );
          const transcriptionsSnapshot = await getDocs(transcriptionsQuery);
          const transcriptions = transcriptionsSnapshot.docs.map((doc) =>
            doc.data()
          );
          const latestTranscription = transcriptions.sort(
            (a, b) => b.createdAt - a.createdAt
          )[0];
          const durationMins = latestTranscription.duration_mins || 0;
          const durationSeconds = durationMins * 60;

          const newTimeLeft = timeLeft - durationSeconds;
          setTimeLeft(newTimeLeft);
          await updateTimeLeftInFirestore(newTimeLeft);
        }

        setTimeout(() => window.location.reload(), 10000);
      } catch (error) {
        setUploadStatus("File upload failed.");
        console.error(error);

        // Show failure modal and restore time left
        setShowUploadFailure(true);
        await restoreTimeLeft();
      }
    };

    reader.readAsArrayBuffer(fileToUpload);
  };

  const handleBackClick = () => {
    if (isRecording || recordedFile) {
      setIsModalOpen(true);
    } else {
      setShowRecordingButtons(false);
      setShowFileInput(false);
      setShowRecordingDownload(false);
      setUploadStatus("");
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalReload = async () => {
    if (isRecording) {
      await stopRecording();
    }
    setRecordedFile(null);
    setSelectedFile(null);
    setMediaBlobUrl(null);
    setShowRecordingButtons(false);
    setShowFileInput(false);
    setShowRecordingDownload(false);
    setIsModalOpen(false);
    window.location.reload();
  };

  const handleRecordingStart = async () => {
    if (timeLeft < 60) {
      setShowPurchasePrompt(true);
      return;
    }

    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.error("Media devices not supported.");
      alert(
        "Your device does not support recording or microphone access is denied. Please check your browser settings."
      );
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      startRecording(stream);
    } catch (error) {
      console.error("Error accessing microphone:", error);
      alert(
        "Microphone access is required for recording. Please allow microphone access in your browser settings."
      );
    }
  };

  const startRecording = (stream) => {
    const audioContext = new AudioContext();
    const source = audioContext.createMediaStreamSource(stream);
    const analyser = audioContext.createAnalyser();
    source.connect(analyser);
    analyser.fftSize = 2048;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const mediaRecorder = new MediaRecorder(stream);
    mediaRecorderRef.current = mediaRecorder;

    const chunks = [];
    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        chunks.push(event.data);
      }
    };

    mediaRecorder.onstop = () => {
      const blob = new Blob(chunks, { type: "audio/m4a" });
      const blobUrl = URL.createObjectURL(blob);
      handleRecordingStop(blobUrl, blob);
    };

    let lastSoundTime = Date.now();

    const checkSilence = () => {
      analyser.getByteTimeDomainData(dataArray);
      const sum = dataArray.reduce((acc, val) => acc + Math.abs(val - 128), 0);
      const average = sum / bufferLength;
      if (average < 2) {
        // Silence threshold, may need adjustment
        if (Date.now() - lastSoundTime > 10000) {
          // 10000 ms = 10 seconds
          setShowSilenceNotification(true);
          clearInterval(silenceDetection);
        }
      } else {
        lastSoundTime = Date.now();
        setShowSilenceNotification(false);
      }
    };

    const silenceDetection = setInterval(checkSilence, 100); // Check every 100 ms

    mediaRecorder.start();
    setStartTime(Date.now());
    setIsRecording(true);
  };

  const handleRecordingStop = (blobUrl, blob) => {
    console.log("Blob type:", blob.type);
    console.log("Blob size:", blob.size);

    setRecordedFile(blob);
    setMediaBlobUrl(blobUrl);
    setIsRecording(false);
    handleFileUpload(blob);

    // Automatically download the recording
    const a = document.createElement("a");
    a.href = blobUrl;
    a.download = "recording.m4a";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const restoreTimeLeft = async () => {
    const user = auth.currentUser;
    if (user) {
      const userDocRef = doc(firestore, "users", user.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const newTimeLeft = initialTimeLeft; // Restore to initial time left before upload attempt
        setTimeLeft(newTimeLeft);
        await updateTimeLeftInFirestore(newTimeLeft);
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-blue-100 to-purple-200 p-4">
      {showRecordingButtons || showFileInput ? (
        <>
          <button
            className="text-2xl self-start mb-4 text-gray-700 hover:text-gray-900"
            onClick={handleBackClick}
          >
            <FaArrowLeft />
          </button>
          {showFileInput ? (
            <div className="flex flex-col items-center space-y-4 bg-white p-8 rounded-lg shadow-lg">
              <p className="text-xl font-medium text-gray-700">
                Please upload an audio file
              </p>
              <div className="flex flex-col items-center space-y-2">
                <label className="cursor-pointer bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200">
                  <input
                    type="file"
                    accept="audio/*"
                    onChange={handleFileChange}
                    className="hidden"
                  />
                  Choose file
                </label>
                <span>
                  {selectedFile ? selectedFile.name : "No file chosen"}
                </span>
              </div>
              <div className="flex items-center relative">
                <button
                  onClick={() => handleFileUpload(selectedFile)}
                  className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200 focus:ring-4 focus:ring-blue-300"
                >
                  <FaUpload className="inline-block mr-2" />
                  Upload Audio
                </button>
                {timeLeft < 60 && (
                  <div
                    className="absolute inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75 rounded-md cursor-not-allowed"
                    onClick={() => setShowPurchasePrompt(true)}
                  ></div>
                )}
              </div>
              <p className="text-lg text-gray-700">{uploadStatus}</p>
              <p className="text-center text-gray-700">How to use:</p>
              <ol className="list-decimal list-inside text-left text-gray-700">
                <li>Press "choose file" and upload an audio file.</li>
                <li>Press the upload button to upload the recording.</li>
                <li>
                  The recording must be{" "}
                  <span className="font-bold">more than a minute</span> long.
                </li>
              </ol>
            </div>
          ) : (
            <div className="flex flex-col items-center space-y-4 bg-white p-8 rounded-lg shadow-lg">
              {!showRecordingDownload && (
                <>
                  <p className="text-xl font-medium text-gray-700">
                    Press the button to start recording
                  </p>
                  <div className="flex items-center relative">
                    {isRecording && <div className="live-signal mr-2"></div>}
                    <button
                      onClick={
                        isRecording ? stopRecording : handleRecordingStart
                      }
                      className={`${
                        isRecording ? "bg-red-500" : "bg-blue-500"
                      } text-white px-4 py-2 rounded-md hover:bg-opacity-80 transition duration-200 focus:ring-4 focus:ring-${
                        isRecording ? "red" : "blue"
                      }-300 flex items-center`}
                    >
                      <FaMicrophone className="inline-block" />
                      {isRecording ? " Stop Recording" : " Start Recording"}
                    </button>
                    {timeLeft < 60 && !isRecording && (
                      <div
                        className="absolute inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75 rounded-md cursor-not-allowed"
                        onClick={() => setShowPurchasePrompt(true)}
                      ></div>
                    )}
                  </div>
                  <p className="text-lg text-gray-700">
                    Time left: {formatTime(timeLeft)}
                  </p>
                  <p className="text-center text-gray-700">How to use:</p>
                  <ol className="list-decimal list-inside text-left text-gray-700">
                    <li>Click start/stop recording for recording.</li>
                    <li>
                      Recording must be{" "}
                      <span className="font-bold">at least 1 minute</span> long,
                      if not there will be an error processing.
                    </li>
                    <li>
                      The audio file will be automatically downloaded and the
                      transcript will be sent to your mailbox.
                    </li>
                    <li>The page will reload after successful upload.</li>
                  </ol>
                </>
              )}
              {showRecordingDownload && (
                <p className="text-lg text-gray-700">{uploadStatus}</p>
              )}
            </div>
          )}
        </>
      ) : (
        <div className="flex flex-col items-center space-y-4 bg-white p-8 rounded-lg shadow-lg">
          <p className="text-xl font-medium text-gray-700">
            Choose one of the below
          </p>
          <div className="flex space-x-4">
            <button
              onClick={() => {
                setShowRecordingButtons(true);
                setShowFileInput(false);
                setUploadStatus("");
              }}
              className="bg-blue-500 text-white px-4 py-2 rounded-md flex items-center hover:bg-blue-600 transition duration-200 focus:ring-4 focus:ring-blue-300"
            >
              <FaMicrophone className="inline-block mr-2" />
              Record Audio
            </button>
            <button
              onClick={() => {
                setShowFileInput(true);
                setShowRecordingButtons(false);
                setUploadStatus("");
              }}
              className="bg-blue-500 text-white px-4 py-2 rounded-md flex items-center hover:bg-blue-600 transition duration-200 focus:ring-4 focus:ring-blue-300"
            >
              <FaUpload className="inline-block mr-2" />
              Upload Audio File
            </button>
          </div>
        </div>
      )}
      {isModalOpen && (
        <Modal onClose={handleModalClose} onReload={handleModalReload} />
      )}
      {showSilenceNotification && isRecording && (
        <div
          className="absolute top-20 left-1/2 transform -translate-x-1/2 p-6 bg-white shadow-xl rounded-lg text-center border border-gray-200"
          style={{ zIndex: 1000, animation: "fadeIn 0.3s" }}
        >
          <p className="text-gray-800 text-lg font-medium mb-4">
            We detected that no one is talking. If the recording has ended, you
            may want to stop.
          </p>
          <div className="flex justify-around items-center">
            <button
              className="text-gray-500 hover:text-gray-800 transition duration-150 ease-in-out"
              onClick={() => setShowSilenceNotification(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
      {showPurchasePrompt && (
        <PurchasePromptModal onClose={() => setShowPurchasePrompt(false)} />
      )}
      {showTimeWarning && (
        <TimeWarningModal
          onClose={() => setShowTimeWarning(false)}
          message={
            timeLeft < 30
              ? "Your remaining time is less than 30 seconds. Please end the recording soon to save your progress."
              : "Your remaining time is less than a minute. Please end the recording soon to save your progress."
          }
        />
      )}
      {showUploadFailure && (
        <UploadFailureModal onClose={() => window.location.reload()} />
      )}
    </div>
  );
};

export default MainPage;
