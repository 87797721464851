import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import CheckoutForm from "./CheckoutForm";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  collection,
  addDoc,
  onSnapshot,
} from "firebase/firestore";

const stripePromise = loadStripe(
  "pk_live_51PQQPzLXWy1XGm3Ed1LbWSx162KdfeWrcqgB3VBKtM3LOo5j98fk1t9hpH5HvDjGU9RHRivNzotvwHYHY8VZuBoM00bA0H8ZDN"
);

const Price = () => {
  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handlePurchase = async (priceId) => {
    setLoading(true);
    const auth = getAuth();
    const user = auth.currentUser;
    const db = getFirestore();

    const priceMapping = {
      price_1PvWfLLXWy1XGm3EYbe9ZQcm: {
        amount: "$3.00",
        plan: "Basic Plan",
        description: "60 Minutes",
      },
      price_1PvWhtLXWy1XGm3E2tisJi54: {
        amount: "$19.80",
        plan: "Intermediate Plan",
        description: "480 Minutes",
      },
      price_1PvWj0LXWy1XGm3Esmewict8: {
        amount: "$403.75",
        plan: "Advanced Plan",
        description: "10560 Minutes",
      },
    };

    if (user) {
      const planDetails = priceMapping[priceId];

      const createCheckoutSession = async (retries = 0) => {
        try {
          const docRef = await addDoc(
            collection(db, `customers/${user.uid}/checkout_sessions`),
            {
              mode: "payment",
              price: priceId,
              success_url:
                window.location.origin +
                "/payment-success?session_id={CHECKOUT_SESSION_ID}",
              cancel_url: window.location.origin,
              metadata: {
                priceId: priceId,
                userId: user.uid,
                ...planDetails,
              },
            }
          );

          const unsubscribe = onSnapshot(docRef, (snap) => {
            const data = snap.data();
            if (data?.error) {
              alert(`An error occurred: ${data.error.message}`);
              setLoading(false);
            }
            if (data?.url) {
              window.location.assign(data.url);
            }
          });

          return () => unsubscribe();
        } catch (error) {
          if (error.statusCode === 429 && retries < 3) {
            const delay = Math.pow(2, retries) * 1000;
            await new Promise((resolve) => setTimeout(resolve, delay));
            return createCheckoutSession(retries + 1);
          }
          alert(`Error creating checkout session: ${error.message}`);
          setLoading(false);
          throw error;
        }
      };

      await createCheckoutSession();
    } else {
      navigate("/login");
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="bg-gradient-to-r from-purple-500 to-indigo-600 text-white py-12">
        <div className="container mx-auto text-center">
          <h1 className="text-4xl font-bold mb-4">Choose Your Plan</h1>
          <p className="text-lg max-w-xl mx-auto">
            Select a plan that suits your needs.
          </p>
        </div>
      </div>
      <div className="container mx-auto p-4">
        <div className="flex flex-col lg:flex-row justify-center my-8 space-y-6 lg:space-y-0 lg:space-x-6">
          <PlanCard
            title="Basic Plan"
            hours="60 Minutes"
            price="3"
            discount="-"
            totalPayment="3"
            priceId="price_1PvWfLLXWy1XGm3EYbe9ZQcm"
            handlePurchase={handlePurchase}
          />
          <PlanCard
            title="Intermediate Plan"
            hours="480 Minutes"
            price="22"
            discount="10%"
            totalPayment="19.80"
            priceId="price_1PvWhtLXWy1XGm3E2tisJi54"
            handlePurchase={handlePurchase}
            featured
          />
          <PlanCard
            title="Advance Plan"
            hours="10560 Minutes"
            price="475"
            discount="15%"
            totalPayment="403.75"
            priceId="price_1PvWj0LXWy1XGm3Esmewict8"
            handlePurchase={handlePurchase}
          />
        </div>
        {loading && (
          <div className="flex justify-center items-center mt-4">
            <ClipLoader color="#4a90e2" loading={loading} size={40} />
          </div>
        )}
        {clientSecret && (
          <Elements stripe={stripePromise}>
            <CheckoutForm clientSecret={clientSecret} />
          </Elements>
        )}
      </div>
    </div>
  );
};

const PlanCard = ({
  title,
  hours,
  price,
  discount,
  totalPayment,
  priceId,
  handlePurchase,
  featured,
}) => (
  <div
    className={`border rounded-lg text-center p-8 shadow-lg transition-transform transform hover:scale-105 flex flex-col justify-between ${
      featured
        ? "bg-gradient-to-r from-yellow-400 to-orange-500 text-white"
        : "bg-white"
    }`}
  >
    {featured && (
      <div className="absolute top-0 right-0 m-2 bg-red-500 text-white text-xs px-2 py-1 rounded-full">
        Most Popular
      </div>
    )}
    <div>
      <div className="text-2xl font-bold mb-2">{title}</div>
      <div className="text-5xl font-bold py-4">{hours}</div>
      <div className="text-lg my-3">
        ${price} <span className="text-sm">SGD</span>
      </div>
      <div
        className={`text-sm my-3 ${
          discount
            ? "bg-green-200 text-green-800 px-3 py-1 rounded-full inline-block"
            : ""
        }`}
        style={{ minHeight: "24px" }} // Consistent height for discount section
      >
        {discount ? `Discount: ${discount}` : ""}
      </div>
      <div
        className="text-lg my-3 font-semibold"
        style={{ minHeight: "24px" }} // Consistent height for total payment section
      >
        {totalPayment ? `Total: $${totalPayment} SGD` : ""}
      </div>
    </div>
    <button
      onClick={() => handlePurchase(priceId)}
      className="bg-indigo-600 text-white font-bold uppercase text-xs mt-5 py-3 px-6 rounded cursor-pointer hover:bg-indigo-700 transition duration-300"
    >
      Purchase
    </button>
  </div>
);

export default Price;
