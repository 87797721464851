import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/authContext";
import { doSignOut } from "../../firebase/auth";

const Header = () => {
  const navigate = useNavigate();
  const { userLoggedIn } = useAuth();
  const [activeButton, setActiveButton] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleButtonClick = (buttonName, path) => {
    setActiveButton(buttonName);
    if (userLoggedIn) {
      navigate(path);
    } else {
      navigate("/login");
    }
  };

  return (
    <header className="bg-white shadow-md sticky top-0 z-50">
      <nav className="container mx-auto p-4 flex justify-between items-center">
        <div className="flex items-center space-x-6">
          <button
            onClick={() => {
              setActiveButton("pricing");
              navigate("/pricing");
            }}
            className={`${
              activeButton === "pricing"
                ? "text-blue-600 bg-blue-100 px-3 py-2 rounded-md text-lg transform scale-105 transition-transform duration-300"
                : "text-gray-700 hover:text-blue-600 hover:bg-blue-50 px-3 py-2 rounded-md text-lg transition-all duration-300 transform hover:scale-105"
            }`}
          >
            Pricing
          </button>
          <div className="relative group">
            <div
              className={`${
                activeButton === "services"
                  ? "text-blue-600 bg-blue-100 px-3 py-2 rounded-md cursor-default text-lg"
                  : "text-gray-700 hover:text-blue-600 transition duration-300 cursor-default text-lg"
              }`}
            >
              Services
            </div>
            <div className="absolute left-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg py-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <button
                onClick={() => handleButtonClick("recording", "/")}
                className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-blue-100 transition duration-300 text-lg"
              >
                Recording
              </button>
              <button
                onClick={() => handleButtonClick("history", "/history")}
                className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-blue-100 transition duration-300 text-lg"
              >
                History
              </button>
            </div>
          </div>
        </div>
        <div className="text-4xl font-bold text-gray-800">
          <NavLink to="https://quiklah.com" className="hover:text-blue-600">
            Quiklah
          </NavLink>
        </div>
        <div className="hidden md:flex items-center space-x-4">
          {userLoggedIn ? (
            <button
              onClick={() => {
                doSignOut().then(() => navigate("/login"));
              }}
              className="text-gray-700 hover:text-blue-600 transition duration-300 text-lg"
            >
              Logout
            </button>
          ) : (
            <>
              <button
                onClick={() => navigate("/login")}
                className="px-4 py-2 border border-blue-600 text-blue-600 rounded hover:bg-blue-600 hover:text-white transition duration-300 text-lg"
              >
                Login
              </button>
              <button
                onClick={() => navigate("/register")}
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-300 text-lg"
              >
                Sign Up
              </button>
            </>
          )}
        </div>
        {/* Mobile Menu */}
        <div className="md:hidden flex items-center">
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="text-gray-700 hover:text-blue-600 transition duration-300 focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          </button>
        </div>
      </nav>
      {/* Mobile Dropdown Menu */}
      {isDropdownOpen && (
        <div className="md:hidden bg-white border-t border-gray-200 shadow-lg">
          <div className="px-4 py-2">
            <button
              onClick={() => handleButtonClick("pricing", "/pricing")}
              className={`${
                activeButton === "pricing"
                  ? "text-blue-600 bg-blue-100 px-3 py-2 rounded-md text-lg transform scale-105 transition-transform duration-300"
                  : "text-gray-700 hover:text-blue-600 hover:bg-blue-50 px-3 py-2 rounded-md text-lg transition-all duration-300 transform hover:scale-105"
              } py-2`}
            >
              Pricing
            </button>
            <button
              onClick={() => handleButtonClick("recording", "/")}
              className="block w-full text-left text-gray-700 hover:text-blue-600 transition duration-300 py-2 text-lg"
            >
              Recording
            </button>
            <button
              onClick={() => handleButtonClick("history", "/history")}
              className="block w-full text-left text-gray-700 hover:text-blue-600 transition duration-300 py-2 text-lg"
            >
              History
            </button>
            {userLoggedIn ? (
              <button
                onClick={() => {
                  doSignOut().then(() => navigate("/login"));
                  setIsDropdownOpen(false);
                }}
                className="block w-full text-left text-gray-700 hover:text-blue-600 transition duration-300 py-2 text-lg"
              >
                Logout
              </button>
            ) : (
              <>
                <button
                  onClick={() => handleButtonClick("login", "/login")}
                  className="block w-full text-left text-gray-700 hover:text-blue-600 transition duration-300 py-2 text-lg"
                >
                  Login
                </button>
                <button
                  onClick={() => handleButtonClick("signup", "/register")}
                  className="block w-full text-left text-gray-700 hover:text-blue-600 transition duration-300 py-2 text-lg"
                >
                  Sign Up
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
