import React from "react";

const Modal = ({ onClose, onReload }) => {
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-xl font-bold mb-4">Warning</h2>
        <p className="mb-4">
          The time consumed will be deducted from your total time. Do you want
          to proceed?
        </p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="bg-gray-500 text-white px-4 py-2 rounded-md"
          >
            Cancel
          </button>
          <button
            onClick={onReload}
            className="bg-red-500 text-white px-4 py-2 rounded-md"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
