import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  collection,
  addDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get("session_id");

    if (sessionId) {
      const fetchData = async () => {
        const user = auth.currentUser;
        if (user) {
          const sessionRef = collection(
            db,
            `customers/${user.uid}/checkout_sessions`
          );
          const sessionDoc = await getDocs(
            query(sessionRef, where("sessionId", "==", sessionId))
          );

          if (!sessionDoc.empty) {
            const sessionData = sessionDoc.docs[0].data();

            await addDoc(collection(db, `customers/${user.uid}/transactions`), {
              sessionId: sessionId,
              status: "success",
              plan: sessionData.metadata.plan,
              amount: sessionData.metadata.amount,
              description: sessionData.metadata.description,
              created: new Date(),
            });

            navigate("/history");
          }
        }
      };

      fetchData();
    }
  }, [auth, db, location.search, navigate]);

  return (
    <div>
      <h1>Payment Successful!</h1>
      <p>Redirecting to your history page...</p>
    </div>
  );
};

export default PaymentSuccess;
