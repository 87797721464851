import React, { useState } from "react";
import { Navigate, Link } from "react-router-dom";
import { doSignInWithEmailAndPassword } from "../../../firebase/auth";
import { useAuth } from "../../../context/authContext";
import PlanCard from "./PlanCard";

const Login = () => {
  const { userLoggedIn } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [errorMessage, setError] = useState(null);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!isSigningIn) {
      setIsSigningIn(true);
      try {
        await doSignInWithEmailAndPassword(email, password);
      } catch (error) {
        setIsSigningIn(false);
        setError("Incorrect email or password.");
      }
    }
  };

  if (userLoggedIn) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-100 to-purple-200">
      <div className="bg-white shadow-2xl rounded-lg flex flex-col md:flex-row w-full max-w-7xl overflow-hidden">
        <div className="md:w-1/2 p-8 md:p-12">
          <div className="text-center mb-8">
            <Link to="https://quiklah.com">
              <img
                src="quiklah.jpeg"
                alt="quiklah image"
                className="mx-auto mb-6 w-48 md:w-64 transition-transform duration-300 transform hover:scale-105"
              />
            </Link>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-800 mb-6">
              Sign In
            </h2>
          </div>
          <form onSubmit={onSubmit} className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-bold text-gray-600"
              >
                Email
              </label>
              <input
                id="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full mt-2 px-4 py-3 text-gray-700 bg-gray-100 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500 transition duration-300"
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-bold text-gray-600"
              >
                Password
              </label>
              <input
                id="password"
                type="password"
                autoComplete="current-password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full mt-2 px-4 py-3 text-gray-700 bg-gray-100 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500 transition duration-300"
              />
            </div>
            {errorMessage && (
              <span className="block text-red-600 font-bold text-sm">
                {errorMessage}
              </span>
            )}
            <button
              type="submit"
              disabled={isSigningIn}
              className={`w-full px-4 py-3 text-white font-medium rounded-lg ${
                isSigningIn
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-indigo-600 hover:bg-indigo-700 transition duration-300"
              }`}
            >
              {isSigningIn ? "Signing In..." : "Sign In"}
            </button>
          </form>
          <div className="text-center mt-6">
            <Link to="/forgetpassword" className="text-sm hover:underline">
              Forgot password?
            </Link>
          </div>
          <div className="text-center mt-6">
            <span className="text-sm text-gray-600">New to Quiklah? </span>
            <Link to="/register" className="text-sm font-bold hover:underline">
              SIGNUP HERE
            </Link>
          </div>
        </div>
        <div className="md:w-1/2 p-8 md:p-12 bg-gray-50 flex flex-col items-center justify-center">
          <h1 className="relative text-center font-bold text-3xl md:text-4xl mb-8 tracking-wide">
            Pricing
            <span className="absolute left-1/2 transform -translate-x-1/2 bottom-[-10px] w-3/4 h-1 bg-blue-500"></span>
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <PlanCard
              title="Basic Plan"
              hours="1 Hour"
              minutes="60 Minutes"
              price="3.00"
            />
            <PlanCard
              title="Intermediate Plan"
              hours="8 Hours"
              minutes="480 Minutes"
              price="19.80"
              originalPrice="22.00"
              discountPercentage="10"
            />
            <PlanCard
              title="Advanced Plan"
              hours="176 Hours"
              minutes="10560 Minutes"
              price="403.75"
              originalPrice="475.00"
              discountPercentage="15"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
