import { auth, firestore } from "./firebase";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  updatePassword,
} from "firebase/auth";

export const doCreateUserWithEmailAndPassword = async (
  email,
  password,
  firstName,
  lastName
) => {
  try {
    // Create user in Firebase Authentication
    const { user } = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    // Save user details in Firestore
    const userDocRef = doc(firestore, "users", user.uid); // Get a reference to the user document
    await setDoc(userDocRef, {
      email,
      firstName,
      lastName,
      createdAt: serverTimestamp(),
      uid: user.uid,
      timeLeft: 15 * 60, // Initialize with 15 minutes in seconds
    });

    await sendEmailVerification(user);

    return user;
  } catch (error) {
    throw error;
  }
};

export const doSignInWithEmailAndPassword = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const doSignOut = () => {
  return auth.signOut();
};

export const doPasswordReset = (email) => {
  return sendPasswordResetEmail(auth, email);
};

export const doPasswordChange = (password) => {
  return updatePassword(auth.currentUser, password);
};

export const doSendEmailVerification = () => {
  return sendEmailVerification(auth.currentUser, {
    url: `${window.location.origin}/home`,
  });
};
