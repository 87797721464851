import React, { useState } from "react";
import { Navigate, Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/authContext";
import { doCreateUserWithEmailAndPassword } from "../../../firebase/auth";

const Register = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isRegistering, setIsRegistering] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { userLoggedIn } = useAuth();

  const validateForm = () => {
    if (!firstName || !lastName || !email || !password || !confirmPassword) {
      setErrorMessage("All fields are required.");
      return false;
    }
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return false;
    }
    setErrorMessage("");
    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!isRegistering && validateForm()) {
      setIsRegistering(true);
      try {
        await doCreateUserWithEmailAndPassword(
          email,
          password,
          firstName,
          lastName
        );
        navigate("/");
      } catch (error) {
        setIsRegistering(false);
        setErrorMessage("Error creating account.");
      }
    }
  };

  return (
    <>
      {userLoggedIn && <Navigate to={"/"} replace={true} />}

      <main className="flex items-center justify-center w-full h-screen bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 p-4">
        <div className="bg-white shadow-2xl rounded-lg flex flex-col md:flex-row w-full max-w-4xl">
          <div className="md:w-1/2 p-8">
            <div className="text-center mb-6">
              <h3 className="text-gray-800 text-2xl font-semibold">
                Create a New Account
              </h3>
            </div>
            <form onSubmit={onSubmit} className="space-y-6">
              <div>
                <label className="text-sm text-gray-600 font-bold">
                  First Name
                </label>
                <input
                  type="text"
                  autoComplete="given-name"
                  required
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="w-full mt-2 px-4 py-3 text-gray-700 bg-gray-100 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500 transition duration-300"
                />
              </div>
              <div>
                <label className="text-sm text-gray-600 font-bold">
                  Last Name
                </label>
                <input
                  type="text"
                  autoComplete="family-name"
                  required
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="w-full mt-2 px-4 py-3 text-gray-700 bg-gray-100 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500 transition duration-300"
                />
              </div>
              <div>
                <label className="text-sm text-gray-600 font-bold">Email</label>
                <input
                  type="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full mt-2 px-4 py-3 text-gray-700 bg-gray-100 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500 transition duration-300"
                />
              </div>
              <div>
                <label className="text-sm text-gray-600 font-bold">
                  Password
                </label>
                <input
                  type="password"
                  autoComplete="new-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full mt-2 px-4 py-3 text-gray-700 bg-gray-100 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500 transition duration-300"
                />
              </div>
              <div>
                <label className="text-sm text-gray-600 font-bold">
                  Confirm Password
                </label>
                <input
                  type="password"
                  autoComplete="off"
                  required
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="w-full mt-2 px-4 py-3 text-gray-700 bg-gray-100 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500 transition duration-300"
                />
              </div>
              {errorMessage && (
                <span className="text-red-600 font-bold">{errorMessage}</span>
              )}
              <button
                type="submit"
                disabled={isRegistering}
                className={`w-full px-4 py-3 text-white font-medium rounded-lg ${
                  isRegistering
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-indigo-600 hover:bg-indigo-700 transition duration-300"
                }`}
              >
                {isRegistering ? "Signing Up..." : "Sign Up"}
              </button>
              <div className="text-sm text-center mt-4">
                Already have an account?{" "}
                <Link
                  to={"/login"}
                  className="text-indigo-600 hover:underline font-bold"
                >
                  Continue
                </Link>
              </div>
            </form>
          </div>
          <div className="md:w-1/2 p-8 bg-gray-50 flex items-center justify-center">
            <img
              src="/quiklah.jpeg"
              alt="Illustration"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
        </div>
      </main>
    </>
  );
};

export default Register;
