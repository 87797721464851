import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./component/auth/login/Login";
import Register from "./component/auth/register/Register";
import Header from "./component/header/Header";
import Home from "./component/home/Home";
import MainPage from "./component/mainPage/Mainpage";
import { AuthProvider } from "./context/authContext";
import ForgetPassword from "./component/auth/login/ForgetPassword";
import Price from "./component/pricing/Price";
import HistoryPage from "./component/history/HistoryPage";
import Testing from "./component/mainPage/Testing";
import PaymentSuccess from "./component/pricing/PaymentSuccess";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Header />
        <div className="w-full h-screen flex flex-col">
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            {/* <Route path="/" element={<Home />} /> */}
            <Route path="/forgetpassword" element={<ForgetPassword />} />
            <Route path="/pricing" element={<Price />} />
            <Route path="/history" element={<HistoryPage />} />
            <Route path="/testing" element={<Testing />} />
            <Route path="/payment-success" element={<PaymentSuccess />} />{" "}
            {/* New route */}
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
